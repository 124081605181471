import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const AnatomyOfChallenge = () => (
  <Layout title="Anatomy of a Challenge" className="annual-challenge has-video">
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">Anatomy of a Challenge</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Anatomy of a Challenge</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../voyage-of-ideas" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <div className="videoWrapper">
                <Video
                  guidSrc="0ed1e4ec-769d-47e2-bc92-8c181b7a06c2"
                  className="de-vid"
                ></Video>
              </div>
              <Link to="../why-science" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <h3>
              Discover what the Young Scientist Challenge is made of – and why
              we’re the nation’s premier middle school science competition.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AnatomyOfChallenge;
